/* @import url("https://fonts.googleapis.com/css?family=Gelasio:400,400i,500,500i,600,600i,700,700i|Work+Sans:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@font-face {
  font-family: "Aileron";
  src: url("../fonts/Aileron-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css?family=Muli&display=swap"); */

.text {
  color: #151515;
}

.highlighted-content {
  font-family: "Helvetica", sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.size {
  font-size: 30px;
}
.highlighted-content .golden {
  color: #cfa25b;
  font-family: "Helvetica", sans-serif;
  font-weight: 600;
}

.separator-generic {
  border-bottom: thin solid #cfa25b;
  width: 100%;
}

.muli {
  font-family: "Helvetica", sans-serif;
}

li {
  margin: 3% 0;
  margin-left: -3%;
}

#header {
  background-color: #000;
  color: #fdfdfd;
  padding: 10px;
  letter-spacing: -0.05em;
}

.font {
  font-family: "Helvetica", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica", sans-serif;
}

.headerFont {
  font-size: 14px;
  display: inline-block;
  padding-left: 10px;
}

@media(max-width: 450px) {
  .headerFont {
    font-size: 13px;
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .headerFont {
    font-size: 20px;
    text-align: center;
    margin: auto;
    display: inline-block;
  }
  #header {
    padding-left: 30px;
  }
}

.link {
  text-decoration: none;
  color: #000;
}

.link:hover {
  text-decoration: underline;
}